@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

.background {
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pageTitle {
    justify-content: center;
    display: flex;
    color: rgb(136, 77, 204);
    text-align: center;
    font-weight: 700;
    font-size: 45px;
}

.paragraph {
    color: rgb(136, 77, 204); 
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    text-align-last: center;
    font-weight: 600;
    padding: 20px;
    
}

.container {
    padding: 20px;
    max-width: 300px;    
    height: fit-content;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(63, 0, 136);
    border-radius: 3px 13px 3px 13px;
    box-shadow: 10px 10px 10px 2px #B5B1C1;
    background-color: rgb(136, 77, 204);
    margin-left: 20px;
    margin-right: 20px;
}

.text {
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
    color: #E7D8EB;
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
}

.randomButton {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(75deg , #e2fdfd 1%, rgb(206, 189, 255)) !important;
    font-family: 'Spartan' !important;
    font-weight: 600 !important;
    color: rgb(136, 77, 204) !important;
    border-radius: 2px 10px 2px 10px !important;
}

.randomButton:hover {
    -webkit-box-shadow:inset 0px 0px 0px 2px #E7D8EB !important;
    -moz-box-shadow:inset 0px 0px 0px 2px #E7D8EB !important;
    box-shadow:inset 0px 0px 0px 2px #E7D8EB !important;
    background: rgb(136, 77, 204) !important;
    color: #E7D8EB !important;
    
}

.cryptoHeader {
    display: flex;
    justify-content: space-between;
}
 
/* tablet */

@media only screen and (max-width: 768px ) {
    .background {
        margin-top: 50px;
    }

    .paragraph {
        font-size: .8rem;
        letter-spacing: -.8px;
        padding: 4vw;
    }
}