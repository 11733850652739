@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    background: linear-gradient( 75deg , #d6fdfd 10%, rgb(192, 172, 252));
    font-size: 16px;
    line-height: 1.4em;
    font-family: 'Spartan';
    background-attachment: fixed;
}

h1 {
    line-height: 1.4em;
}

h2 {
    font-weight: 500;
}