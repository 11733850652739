@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');


.cryptoContainer {
    margin-top: 200px;
    display: flex;
    width: 50%;
    max-width: 700px;
    border: 1px solid rgb(193 138 255);
    height: fit-content;
    flex-direction: column;
    border-radius: 3px 13px 3px 13px;;
    padding: 40px;
    background: rgb(136, 77, 204);
    margin-left: auto;
    margin-right: auto;
    column-count: 2;
}

.cryptoButton {
    /* display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(75deg , #e2fdfd 1%, rgb(206, 189, 255)) !important;
    font-family: 'Spartan' !important;
    font-weight: 500 !important;
    color: rgb(136, 77, 204) !important;
    border: solid 1px rgb(193 138 255) !important;
    border-radius: 3px 13px 3px 13px !important;
    margin: 2px !important;
    padding-top: 1vw; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: linear-gradient(75deg , #e2fdfd 1%, rgb(206, 189, 255)) !important;
    font-family: 'Spartan' !important;
    font-weight: 600 !important;
    color: rgb(136, 77, 204) !important;
    border-radius: 2px 10px 2px 10px !important;
    margin-top: 10px !important;
}

.logo {
    min-width: 50px;
    max-width: 60px;
    min-height: 50px;
    max-height: 60px;
    order: 1;
}

.cryptoContent {
    display: flex;
    flex-direction: column;
    
}

.socials {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
}

.cryptoAll {
    display: flex;
    flex-direction: column;
}

.cryptoData {
    display: flex;
    justify-content: space-between;
    color: #E7D8EB;
}


/* small laptop */

@media screen and (max-width: 1024px) {
    .cryptoContainer {
        margin-top: 50px;
    }

    h2 {
        line-height: 1;
        font-size: 1rem;
    }
}

/* tablet */

@media screen and (max-width: 787px) {
    .cryptoContainer{
        margin-top: 50px;
    }

    .cryptoData {
        flex-direction: column;
        align-items: center;
    }

    .logo {
        order: 0;
    }

    .cryptoContent {
        align-items: center;
    }

    h2 {
        text-align: center;
        line-height: 1.75;
        font-size: .8rem;
    }

    h1 {
        text-align: center;
        margin-top: .5vw;
        font-size: 1.5rem;
    }
    
    .percentChange {
        padding-top: 0;
    }
}

/* mobile */

@media screen and (max-width: 400px) {
    .cryptoContainer{
        margin-top: 50px;
    }
}
    

h2 {
    padding: 5px;   
}